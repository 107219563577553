import { useEffect, useState } from "react";
// for work_images
import work1 from "../assets/images/work_images/1.jpg";
import work2 from "../assets/images/work_images/2.jpg";
import work3 from "../assets/images/work_images/3.jpg";
import work4 from "../assets/images/work_images/4.jpg";
import work5 from "../assets/images/work_images/5.jpg";
import work6 from "../assets/images/work_images/6.jpg";
import work7 from "../assets/images/work_images/7.jpg";
import work8 from "../assets/images/work_images/8.jpg";
import work9 from "../assets/images/work_images/9.jpg";
// works small images
import workSmall1 from "../assets/images/work_images/small/1.jpg";
import workSmall2 from "../assets/images/work_images/small/2.jpg";
import workSmall3 from "../assets/images/work_images/small/3.jpg";
import workSmall4 from "../assets/images/work_images/small/4.jpg";
import workSmall5 from "../assets/images/work_images/small/5.jpg";
import workSmall6 from "../assets/images/work_images/small/6.jpg";
import workSmall7 from "../assets/images/work_images/small/7.jpg";
import workSmall8 from "../assets/images/work_images/small/8.jpg";
import workSmall9 from "../assets/images/work_images/small/9.jpg";
// blog post images
// import blog6 from "../assets/images/blog_images/6.jpg";
// import blog4 from "../assets/images/blog_images/4.jpg";
// import blog2 from "../assets/images/blog_images/2.jpg";
// import blog1 from "../assets/images/blog_images/1.jpg";
// import blog3 from "../assets/images/blog_images/3.jpg";
// import blog5 from "../assets/images/blog_images/5.jpg";
// // blog image small
// import blogSmall6 from "../assets/images/blog_images/small/6.jpg";
// import blogSmall4 from "../assets/images/blog_images/small/4.jpg";
// import blogSmall2 from "../assets/images/blog_images/small/2.jpg";
// import blogSmall1 from "../assets/images/blog_images/small/1.jpg";
// import blogSmall3 from "../assets/images/blog_images/small/3.jpg";
// import blogSmall5 from "../assets/images/blog_images/small/5.jpg";

import img1 from "../assets/images/slider/brand-1.png";
import img2 from "../assets/images/slider/brand-2.png";
import img3 from "../assets/images/slider/brand-3.png";
import img4 from "../assets/images/slider/brand-4.png";
import img5 from "../assets/images/slider/brand-5.png";
//  icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
import icon2 from "../assets/images/icons/icon-3.svg";
import icon3 from "../assets/images/icons/icon-4.svg";
import icon4 from "../assets/images/icons/icon-5.svg";
import icon5 from "../assets/images/icons/icon-6.svg";
// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaBlogger, FaRegUser } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  // const handleBlogsData = (id) => {
  //   const find = blogsData.find((item) => item?.id === id);
  //   setSingleData(find);
  //   setIsOpen(true);
  // };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    {
      id: "1",
      tag: "Web Development",
      project: "Fullstack Website",
      title: "Adv-Care Hospital EHR",
      img: work1,
      imgSmall: workSmall1,
      bg: "#FFF0F0",
      client: "Advantage Digital",
      langages: "Wordpress, Photoshop, Figma",
      link: "/resume",
      linkText: "Included in CV",
      description:
        "Full-stack website development project for Adv-Care Hospital's electronic health record (EHR) system. User-friendly interface for healthcare professionals to access and manage patient records, including demographics, medical history, medications, test results, and more. Responsive design for desktops, laptops, tablets, and smartphones. Optimized for speed and performance. Developed using WordPress, Photoshop, and Figma.",
    },
    {
      id: "2",
      tag: "Web Development",
      project: "Fullstack Website",
      title: "Adv Schools",
      img: work2,
      imgSmall: workSmall2,
      bg: "#FFF3FC",
      client: "Advantage Digital",
      langages: "Laravel, MySQL, Photoshop",
      link: "/resume",
      linkText: "Included in CV",
      description:
        "Adv Schools LMS Web Development Project - A full-stack website for Advantage Digital's learning management system (LMS). Built with Laravel, MySQL, and Photoshop. Includes a user-friendly interface, course management tools, and student progress tracking. Preview available in CV.",
    },
    {
      id: "3",
      tag: "UI/UX, Mobile Development",
      project: "Mobile Design & Development",
      title: "Workouts",
      img: work3,
      imgSmall: workSmall3,
      bg: "#FFF0F0",
      client: "Workouts Inc.",
      langages: "Figma, Photoshop, React Native",
      link: "/resume",
      linkText: "Included in CV",
      description:
        "Workouts UI/UX, Mobile Development Project - A mobile design and development project for Workouts Inc. that includes a user-friendly interface, workout tracking features, and personalized fitness plans. Built with Figma, Photoshop, and React Native. Preview available in CV.",
    },
    {
      id: "4",
      tag: "UI/UX, Web Development",
      project: "Multi-Project",
      title: "NurtureMe & Travler",
      img: work4,
      imgSmall: workSmall4,
      bg: "#E9FAFF",
      client: "NurtureMe",
      langages: "ReactJS, Tailwind, Photoshop",
      link: "/resume",
      linkText: "Included in CV",
      description:
        "NurtureMe & Travler UI/UX, Web Development Project - A multi-project web development project for NurtureMe that includes a user-friendly interface, personalized health and wellness plans for plants. Travler website. Built with ReactJS, Tailwind, and Photoshop. Preview available in CV.",
    },
    {
      id: "5",
      tag: "Mobile, Web Development",
      project: "Mobile-Website Integration",
      title: "Adv-Care Mobile App",
      img: work5,
      imgSmall: workSmall5,
      bg: "#FFFAE9",
      client: "Advantage Digital",
      langages: "Dart, Flutter",
      link: "/resume",
      linkText: "Included in CV",
      description:
        "Adv-Care Mobile App - A mobile-website integration project for Advantage Digital that connects Adv-Care's healthcare professionals with patients through a user-friendly app. Built with Dart and Flutter. Preview available in CV.",
    },
    {
      id: "6",
      tag: "UI/UX, Web Development",
      project: "Multi-Project",
      title: "Foody",
      img: work6,
      imgSmall: workSmall6,
      bg: "#F4F4FF",
      client: "Foody",
      langages: "Figma, React, Tailwind",
      link: "/resume",
      linkText: "Included in CV",
      description:
        "Foody UI/UX, Web Development Project - A multi-project web development project for Foody that includes a user-friendly interface, personalized meal planning, and food delivery features. Built with Figma, React, and Tailwind. Preview available in CV.",
    },
    {
      id: "7",
      tag: "Mobile Development",
      project: "Mobile App",
      title: "Socialze",
      img: work7,
      imgSmall: workSmall7,
      bg: "#FFF0F8",
      client: "Socialz",
      langages: "Dart, Flutter",
      link: "/resume",
      linkText: "Included in CV",
      description:
        "Socialze Mobile Development Project - A mobile app development project for Socialz that connects users with local events and activities. Built with Dart and Flutter for a seamless user experience. Preview available in CV.",
    },
    {
      id: "8",
      tag: "Web Development",
      project: "Fullstack Website",
      title: "Adv-Travel",
      img: work8,
      imgSmall: workSmall8,
      bg: "#FFF0F8",
      client: "Advantage Digital",
      langages: "Laravel, MySQL",
      link: "/resume",
      linkText: "Included in CV",
      description:
        "Adv-Travel Web Development Project - A full-stack website for Advantage Digital that allows users to search and book travel packages. Built with Laravel and MySQL for a robust and scalable platform. Preview available in CV.",
    },
    {
      id: "9",
      tag: "UI/UX, Mobile, Web Development",
      project: "Mobile-Website Integration",
      title: "Handy Man",
      img: work9,
      imgSmall: workSmall9,
      bg: "#FCF4FF",
      client: "Handy Man",
      langages: "Figma, Flutter, Laravel, MySQL",
      link: "/resume",
      linkText: "Included in CV",
      description:
        "Handy Man UI/UX, Mobile, Web Development Project - A mobile-website integration project for Handy Man that connects users with local handymen. Built with Figma, Flutter, Laravel, and MySQL for a seamless user experience. Preview available in CV.",
    },
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  // const blogsData = [
  //   {
  //     id: "1",
  //     img: blog1,
  //     imgSmall: blogSmall1,
  //     date: "177 April",
  //     category: "Inspiration",
  //     title: "How to Own Your Audience by Creating an Email List.",
  //     bg: "#FCF4FF",
  //     description:
  //       "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
  //   },
  //   {
  //     id: "4",
  //     img: blog4,
  //     imgSmall: blogSmall4,
  //     date: "000 April",
  //     category: "Inspiration",
  //     title: "Everything You Need to Know About Web Accessibility.",
  //     bg: "#EEFBFF",
  //     description:
  //       "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
  //   },
  //   {
  //     id: "2",
  //     img: blog2,
  //     imgSmall: blogSmall2,
  //     date: "21 April",
  //     category: "Web Design",
  //     title: "The window know to say beside you",
  //     bg: "#FFF0F0",
  //     description:
  //       "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
  //   },
  //   {
  //     id: "5",
  //     img: blog5,
  //     imgSmall: blogSmall5,
  //     date: "27 April",
  //     category: "Inspiration",
  //     title: "Top 10 Toolkits for Deep Learning in 2021.",
  //     bg: "#FCF4FF",
  //     description:
  //       "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
  //   },
  //   {
  //     id: "3",
  //     img: blog3,
  //     imgSmall: blogSmall3,
  //     date: "27 April",
  //     category: "Inspiration",
  //     title: "How to Own Your Audience by Creating an Email List.",
  //     bg: "#FCF4FF",
  //     description:
  //       "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
  //   },
  //   {
  //     id: "6",
  //     img: blog6,
  //     imgSmall: blogSmall6,
  //     date: "27 April",
  //     category: "Inspiration",
  //     title: "Everything You Need to Know About Web Accessibility.",
  //     bg: "#EEFBFF",
  //     description:
  //       "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
  //   },
  // ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "06",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/works",
      icon: <FiCodesandbox />,
    },
    // {
    //   id: "04",
    //   name: "Blogs",
    //   link: "/blogs",
    //   icon: <FaBlogger />,
    // },
    // {
    //   id: "05",
    //   name: "Contact",
    //   link: "/contact",
    //   icon: <RiContactsBookLine />,
    // },
  ];

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "About",
      link: "/homeTwo/about",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "Resume",
      link: "/homeTwo/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/homeTwo/works",
      icon: <FiCodesandbox />,
    },
    // {
    //   id: "04",
    //   name: "Blogs",
    //   link: "/homeTwo/blogs",
    //   icon: <FaBlogger />,
    // },
    // {
    //   id: "05",
    //   name: "Contact",
    //   link: "/homeTwo/contact",
    //   icon: <RiContactsBookLine />,
    // },
  ];

  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img1,
    img2,
    img3,
    img4,
    img5,
  ];

  // experience items for about page
  const experienceArray = [
    {
      id: "1",
      icon: icon,
      title: "UI/UX Design",
      des: "Equipped with skills to bring products from idea to reality using industry standard tools.",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
    {
      id: "2",
      icon: icon1,
      title: "App Development",
      des: "Develop any idea into real world native mobile application solution.",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "3",
      icon: icon2,
      title: "Graphic Design",
      des: "Design pieces to appeal to a broad range of audience and to stand out of the competition.",
      color: "#8774FF",
      bg: "#FCF4FF",
    },
    {
      id: "4",
      icon: icon3,
      title: "Photography",
      des: "Capturing the most important moments so that they live longer and others experience them.",
      color: "#FF6080",
      bg: "#FFF4F4",
    },
    {
      id: "5",
      icon: icon4,
      title: "Project Managment",
      des: "Manage and oversee the whole process of ideation, creation and implementation of any solution.",
      color: "#FF75D8",
      bg: "#FFF0F8",
    },
    {
      id: "6",
      icon: icon5,
      title: "Web Development",
      des: "Make solutions available on the web so that customers are not limited to their locations and mobile devices.",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
  ];

  // Resume items for Resume page
  const resumeArray = [
    {
      type: "Education",
      icon: MdOutlineSchool,
      id: "01",
      date: "2019 - 2023",
      title: "BSc. in Computer Science",
      place: "Addis Ababa University",
      bg: "#FFF4F4",

      id1: "02",
      date1: "2017 - 2019",
      title1: "High School",
      place1: "Lideta Catholic Cathedral School",
      bg1: "#FFF1FB",

      id2: "03",
      date2: "2016 - Present",
      title2: "Self-guided Learning",
      place2: "Udemy, Coursera, Skillshare, etc.",
      bg2: "#FFF4F4",
    },
    {
      type: "Experience",
      icon: MdOutlineBusinessCenter,
      id: "04",
      date: "2021 - Present",
      title: "Web Design & Development",
      place: "Freelance, Advantage Digital",
      bg: "#EEF5FA",

      id1: "05",
      date1: "2022 - Present",
      title1: "Mobile App Development",
      place1: "Freelance, Advantage Digital",
      bg1: "#F2F4FF",

      id2: "06",
      date2: "2022 - 2023",
      title2: "Product Design",
      place2: "NurtureMe. Inc.",
      bg2: "#EEF5FA",

      id3: "07",
      date3: "2022 - Present",
      title3: "Digital Marketing",
      place3: "Freelance, Advantage Digital",
      bg3: "#EEF5FA",
    }
    // {
    //   type: "Awards",
    //   icon: FaAward,
    //   id: "07",
    //   date: "2015-2017",
    //   title: "  Graphic Designer",
    //   place: "Web Graphy, Los Angeles, CA",
    //   bg: "#FCF4FF",

    //   id1: "08",
    //   date1: "2014 - 2015",
    //   title1: "Jr. Web Developer",
    //   place1: "Creative Gigs.",
    //   bg1: "#FCF9F2",

    //   id2: "09",
    //   date2: "2015-2017",
    //   title2: "Best Freelancer",
    //   place2: "Fiver & Upwork Level 2 & Top Rated",
    //   bg2: "#FCF4FF",
    // },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#F4F401",
      name: "Web Design",
      number: "100",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "Mobile App Design",
      number: "95",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "Web Development",
      number: "90",
    },
    {
      id: "04",
      color: "#00C6F5",
      name: "User Research",
      number: "98",
    },
    {
      id: "05",
      color: "#F52D00",
      name: "Mobile App Development",
      number: "80",
    },
    {
      id: "06",
      color: "#00F59C",
      name: "Graphics Design",
      number: "85",
    },
    {
      id: "07",
      color: "#CA56F2",
      name: "Digital Marketing",
      number: "75",
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Phone ",
      item1: "+251 978 133 810",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "danielteshome2001@gmail.com",
      item2: "vvavenger04@gmail.com",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "Address ",
      item1: "Addis Ababa, Ethiopia",
      // item2: "Road, New York, USA",
      bg: "#F2F4FF",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    // blogsData,
    // handleBlogsData,
    menuItem,
    NavLink,
    menuItemTwo,
    experienceArray,
    sliderImg,
    resumeArray,
    lineArray,
    contactArray,
  };
};

export default AllData;
