import React from "react";
import userImage from "../../assets/images/about/avatar.jpg";
import downloadIcon from "../../assets/images/download.png";
import {
  FaTelegram,
  FaTwitter,
  FaDribbble,
  FaLinkedinIn,
} from "react-icons/fa";
import PageTitle from "../../Share/PageTitle";
import CV from "../../assets/DanielT_CV.pdf"

const HomePage = () => {
  return (
    <>
      <PageTitle title="Home"></PageTitle>
      {/* End pagetitle */}

      <div
        className="flex flex-col items-center h-[100vh] md:h-[90vh] lg:h-[80vh]  xl:h-[71vh]  justify-center"
        data-aos="fade"
      >
        <img
          className="rounded-full w-[250px] h-[250px] 2xl:w-[280px] 2xl:h-[280px]"
          src={userImage}
          alt=""
        />
        <h3 className="mt-6 mb-1 text-5xl font-semibold dark:text-white">
          Daniel Teshome
        </h3>
        <p className="mb-4 text-[#7B7B7B] dark:text-white text-center">UX/UI Designer, Digital Marketer, Web Developer, Web Designer, App Developer</p>
        {/* Avatar Info End */}

        {/* Social information start */}
        <div className="flex space-x-3">
          <a
            href="https://t.me/+251978133810"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#1773EA]">
              <FaTelegram />
            </span>
          </a>
          {/* <a
            href="https://twitter.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#1C9CEA]">
              <FaTwitter />
            </span>
          </a> */}
          {/* <a
            href="https://dribbble.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#e14a84] ">
              <FaDribbble />
            </span>
          </a> */}
          <a
            href="https://www.linkedin.com/in/daniel-w-designer/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#0072b1]">
              <FaLinkedinIn />
            </span>
          </a>
        </div>
        {/* Social information start */}

        {/* Dowanload button start */}
        <a className="flex items-center bg-gradient-to-r from-[#27005D] to-[#9400FF] transition-all duration-500 ease-linear hover:bg-gradient-to-r hover:from-[#9400FF]  hover:to-[#27005Def] px-8 py-3 text-lg text-white rounded-[35px] mt-6" href={CV}>
          <img src={downloadIcon} alt="icon" className="mr-2" />
          Download CV
        </a>

        {/* Dowanload button End */}
      </div>
    </>
  );
};

export default HomePage;
